import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { Popover } from '../../../popover/index.js';
import { unstyledList } from '../../../../css-utils.css.js';
/**
 * Recursively gets all focusable menu items from a menu element
 * @param menu - The menu element to get items from
 * @returns Array of HTMLElements representing focusable menu items
 */
function getMenuItems(menu) {
    return Array.from(menu?.children ?? []).flatMap((item) => {
        if (item.role === 'menuitem' &&
            item.getAttribute('aria-disabled') !== 'true') {
            return item;
        }
        return getMenuItems(item);
    });
}
/**
 * Menu component that provides keyboard navigation and accessibility features
 * @param props - Menu component props
 * @returns React component
 */
export const Menu = ({ type = 'menu', children, open, ...otherProps }) => {
    const menuList = useRef(null);
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (!menuList.current)
                return;
            const controller = new AbortController();
            if (open) {
                const menuItems = getMenuItems(menuList.current);
                const handleKeyDown = (event) => {
                    const isArrowDown = event.key === 'ArrowDown';
                    const isArrowUp = event.key === 'ArrowUp';
                    if (!isArrowDown && !isArrowUp)
                        return;
                    event.preventDefault();
                    event.stopPropagation();
                    const target = event.target;
                    const firstMenuItem = menuItems[0];
                    const lastMenuItem = menuItems[menuItems.length - 1];
                    const currentIndex = menuItems.indexOf(target);
                    const nextMenuItem = menuItems[currentIndex + 1];
                    const previousMenuItem = menuItems[currentIndex - 1];
                    // Handle focus when target is outside menu
                    if (!menuList.current?.contains(target)) {
                        if (isArrowDown)
                            return firstMenuItem?.focus();
                        if (isArrowUp)
                            return lastMenuItem?.focus();
                    }
                    // Handle focus when target is inside menu
                    if (isArrowDown) {
                        return nextMenuItem ? nextMenuItem.focus() : firstMenuItem?.focus();
                    }
                    if (isArrowUp) {
                        return previousMenuItem
                            ? previousMenuItem.focus()
                            : lastMenuItem?.focus();
                    }
                };
                window.addEventListener('keydown', handleKeyDown, {
                    signal: controller.signal,
                });
            }
            return () => {
                controller.abort();
            };
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [open]);
    return (_jsx(Popover, { type: type, open: open, ...otherProps, children: _jsx("ul", { className: unstyledList, ref: menuList, role: "menu", tabIndex: -1, children: children }) }));
};
