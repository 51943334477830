import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { lazy, Suspense, useId, useMemo, useRef, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { camelCase } from '@knapsack/utils';
import { useStateFromProp } from '@knapsack/hooks';
import { Container } from '../../layout/container/index.js';
import { Divider } from '../../divider/index.js';
import { FormField } from '../../form-field/index.js';
import { Icon } from '../../icon/icon.js';
import { Menu } from '../../menu/index.js';
import { NestedAction } from '../nested-action/index.js';
import { TextBody } from '../../text/index.js';
import { inputContent, inputWrapper, } from '../../../shared-styles/inputs.css.js';
import { findOption } from '../../options/index.js';
import { inputSelect, selectNestedAction, selectStateIndicator, } from './select.css.js';
import { useFilteredSelectOptions, useSelectStateInfo, } from './select.utils.js';
import { SkeletonGroup } from '../../layout/skeleton/index.js';
const Options = lazy(() => import('../../options/options.js'));
const OptionCreateNew = lazy(() => import('../../options/option-create-new.js'));
const SelectedDisplay = lazy(() => import('./select.selected-display.js'));
export const InputSelect = ({ createNew, disableSearch = false, disabled = false, id, isLoading = false, label, name, nestedAction, onBlur, onChange, options = [], groupOptionsLimit, placeholder = 'Choose an option...', readOnly = false, ref, required = false, seamless = false, selectedDisplay = 'symbol', size = 'medium', symbolPosition = 'before', testId, value = '', }) => {
    /** State Management */
    const emptyValue = '';
    const [searchValue, setSearchValue] = useState(emptyValue);
    const [selectState, setSelectState] = useStateFromProp(isLoading ? 'loading' : 'menuClosed');
    const isCreateEnabled = !!createNew;
    const isDisabled = disabled || isLoading;
    const isMenuOpen = selectState === 'menuOpen' || selectState === 'searching';
    /** Ref Management */
    const menuRef = useRef(null);
    const internalRef = useRef(null);
    const inputRef = useMemo(() => mergeRefs([internalRef, ref]), [internalRef, ref]);
    /** Data Management */
    const dataTestId = testId || `${camelCase(label)}Select`;
    const selectOptions = useFilteredSelectOptions({
        options,
        searchValue,
    });
    const { stateIcon, statePlaceholder } = useSelectStateInfo({
        disableSearch,
        placeholder,
        selectState,
    });
    const hasOptions = selectOptions.length > 0;
    const matchingOption = useMemo(() => {
        return findOption({
            matchString: selectState === 'searching' ? searchValue : value,
            options,
        });
    }, [options, searchValue, value, selectState]);
    /** Event Handlers */
    function closeMenu() {
        setSelectState('menuClosed');
        setSearchValue(emptyValue);
        internalRef.current?.blur();
    }
    function handleChange(event) {
        if (isDisabled || disableSearch)
            return;
        setSearchValue(event.currentTarget.value);
    }
    function handleFocus() {
        if (isDisabled)
            return;
        if (disableSearch || selectState === 'searching') {
            setSelectState('menuOpen');
        }
        else {
            setSelectState('searching');
        }
    }
    function handleKeyDown(event) {
        if (isDisabled)
            return;
        if (event.key === 'Enter') {
            // prevent submitting a form
            event.preventDefault();
            event.stopPropagation();
            const option = findOption({
                matchString: event.currentTarget.value,
                options,
            });
            if (option) {
                onChange(option.value);
                closeMenu();
            }
        }
        if (event.key === 'Escape' && selectState === 'searching') {
            setSearchValue(emptyValue);
        }
        if (event.key === 'Escape' && selectState !== 'searching') {
            closeMenu();
        }
        if (event.key === 'Tab') {
            closeMenu();
        }
    }
    const nestedActionProps = nestedAction || {
        icon: 'clear',
        isVisible: (!!matchingOption || !!searchValue) && !isDisabled,
        label: 'Clear',
        onTrigger: () => {
            onChange(emptyValue);
            setSearchValue(emptyValue);
        },
        size: 'xsmall',
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: inputWrapper({ disabled: isDisabled, readOnly, seamless }), "data-testid": dataTestId, ref: menuRef, children: [!!matchingOption && selectState === 'menuClosed' && (_jsx(Suspense, { children: _jsx(SelectedDisplay, { disabled: isDisabled, option: matchingOption, type: selectedDisplay }) })), _jsx("input", { "aria-label": label, autoComplete: "off", className: inputSelect({
                            disableSearch,
                            hideInput: !!matchingOption &&
                                selectedDisplay === 'badge' &&
                                selectState === 'menuClosed',
                            nestedActionVisible: nestedActionProps.isVisible,
                            size,
                        }), "data-testid": `${dataTestId}Input`, disabled: isDisabled, id: id, name: name, onBlur: () => {
                            // default blur behavior is handled by the menu closing from the onClose function
                            if (isDisabled)
                                return;
                            onBlur?.(value);
                        }, onChange: handleChange, onClick: handleFocus, onFocus: handleFocus, onKeyDown: handleKeyDown, placeholder: statePlaceholder, readOnly: readOnly, ref: inputRef, required: required, type: "text", value: searchValue || matchingOption?.label || '' }), _jsx("input", { "data-testid": `${dataTestId}Value`, disabled: isDisabled, readOnly: readOnly, type: "hidden", value: matchingOption?.value || value }), _jsxs("span", { className: inputContent({ placement: 'after' }), children: [nestedActionProps.isVisible && (_jsxs("span", { className: selectNestedAction, children: [_jsx(NestedAction, { hideDivider: true, ...nestedActionProps }), _jsx(Divider, { orientation: "vertical", fixedSize: "24px" })] })), _jsx(Icon, { className: selectStateIndicator, color: isDisabled ? 'disabled' : 'subtle', ...stateIcon })] })] }), !isDisabled && !readOnly && (_jsx(Menu, { anchorEl: menuRef.current, disableFocusTrap: true, fallbackPlacements: ['top-start'], onClose: () => closeMenu(), open: isMenuOpen, placement: "bottom-start", testId: `${dataTestId}Options`, footer: isCreateEnabled &&
                    hasOptions && (_jsx(OptionCreateNew, { ...createNew, existingOptions: options, value: matchingOption ? '' : searchValue, onDismiss: () => {
                        setSelectState('menuOpen');
                    }, onSubmit: (v) => {
                        onChange(v, { isNew: true });
                        closeMenu();
                    } })), children: _jsxs(Suspense, { fallback: _jsx(SkeletonGroup, { count: 5, gap: "xsmall", skeletons: { style: { height: 32, width: 160 } } }), children: [!hasOptions && !isCreateEnabled && (_jsx(Container, { padding: "small", children: _jsx(TextBody, { color: "subtle", children: "No options found" }) })), !hasOptions && isCreateEnabled && (_jsx(OptionCreateNew, { ...createNew, existingOptions: options, onDismiss: () => {
                                setSelectState('menuOpen');
                            }, onSubmit: (v) => {
                                onChange(v, { isNew: true });
                                closeMenu();
                            }, highlighted: !hasOptions, value: searchValue })), hasOptions && (_jsx(Options, { currentValue: matchingOption?.value || value, options: selectOptions, groupOptionsLimit: groupOptionsLimit, onSelect: (v) => {
                                onChange(v);
                                closeMenu();
                            }, searchValue: searchValue, symbolPosition: symbolPosition }))] }) }))] }));
};
export const FieldSelect = ({ 
// shared props
disabled = false, label, required = false, 
// form field props
condensed, error, fieldAction, helperDisplay, helperText, inputWidth, labelPosition, labelWidth, ...inputSelectProps }) => {
    const id = useId();
    return (_jsx(FormField, { condensed: condensed, disabled: disabled, error: error, fieldAction: fieldAction, helperDisplay: helperDisplay, helperText: helperText, id: id, inputWidth: inputWidth, label: label, labelPosition: labelPosition, labelWidth: labelWidth, required: required, children: _jsx(InputSelect, { ...inputSelectProps, disabled: disabled, id: id, label: label, required: required }) }));
};
