import { assertUniqueIdInArray } from '@knapsack/utils';
import { useState } from 'react';
export function isOptionGroup(option) {
    return typeof option === 'object' && 'options' in option;
}
export function assertUniqueOptionValues({ options = [], }) {
    const items = options.flatMap((option) => {
        if (isOptionGroup(option)) {
            return option.options;
        }
        return [option];
    });
    assertUniqueIdInArray({
        items,
        key: 'value',
    });
}
export function findOption({ onlyMatchValue = false, options = [], matchString, }) {
    if (!matchString || matchString === '') {
        return undefined;
    }
    const lowerMatchString = matchString.toLowerCase();
    const matches = (opt) => (!onlyMatchValue && opt.label.toLowerCase() === lowerMatchString) ||
        opt.value.toLowerCase() === lowerMatchString;
    for (const option of options) {
        if (isOptionGroup(option)) {
            const found = option.options.find(matches);
            if (found)
                return found;
        }
        else if (matches(option)) {
            return option;
        }
    }
    return undefined;
}
export function useOptions(opts) {
    const [options, setOptions] = useState(opts);
    const addOption = (option) => {
        setOptions([option, ...options]);
    };
    return {
        options,
        setOptions,
        addOption,
    };
}
