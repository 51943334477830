import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useRef } from 'react';
import clsx from 'clsx';
import { useUsingKeyboard } from '@accessible/using-keyboard';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { TextLabel } from '../text/index.js';
import { Icon } from '../icon/index.js';
import { image, imageContainer, imagePlaceholder, imageVars, } from './image.css.js';
const ImageContents = ({ alt, imgSrc, circle = false, canEdit, disabled = false, size, }) => {
    if (imgSrc) {
        return _jsx("img", { src: imgSrc, className: image({ circle }), alt: alt });
    }
    if (!imgSrc && canEdit) {
        return (_jsxs("div", { className: imagePlaceholder({ circle, disabled }), children: [_jsx(Icon, { symbol: "image", size: size === 'xxsmall' ? 'medium' : 'large' }), !['xxsmall', 'xsmall'].includes(size) && (_jsx(TextLabel, { transform: "uppercase", weight: "bold", children: "Add image" }))] }));
    }
};
const ImageButton = forwardRef((props, ref) => {
    const { ariaLabel, alt, className, disabled = false, imgSrc, onTrigger, size = 'auto', circle = false, testId, maxHeight, maxWidth, ...otherProps } = props;
    const isUsingKeyboard = useUsingKeyboard(false);
    const fileInputRef = useRef(null);
    return (_jsxs("button", { ...otherProps, className: clsx({
            'is-using-keyboard': isUsingKeyboard,
        }, [
            imageContainer({ disabled, size, circle, hasTrigger: true }),
            className,
        ]), "data-testid": testId, onClick: () => {
            fileInputRef.current.click();
        }, ref: ref, style: assignInlineVars(imageVars, { maxHeight, maxWidth }), type: "button", "aria-label": ariaLabel, children: [_jsx(ImageContents, { canEdit: true, disabled: disabled, alt: alt, imgSrc: imgSrc, size: size, circle: circle }), _jsx("input", { disabled: disabled, type: "file", hidden: true, ref: fileInputRef, onChange: (event) => {
                    const fileToUpload = event.target.files[0];
                    onTrigger(fileToUpload);
                } })] }));
});
const ImageBase = forwardRef((props, ref) => {
    const { alt, className, imgSrc, size = 'auto', circle = false, testId, maxHeight, maxWidth, disabled, } = props;
    return (_jsx("div", { className: clsx([imageContainer({ size, circle }), className]), "data-testid": testId, ref: ref, style: assignInlineVars(imageVars, { maxHeight, maxWidth }), "aria-disabled": disabled, children: _jsx(ImageContents, { alt: alt, imgSrc: imgSrc, size: size }) }));
});
const hasButtonProps = (props) => 'onTrigger' in props;
export const Image = React.forwardRef((props, ref) => {
    if (hasButtonProps(props)) {
        return (_jsx(ImageButton, { ref: ref, ...props }));
    }
    return (_jsx(ImageBase, { ref: ref, ...props }));
});
