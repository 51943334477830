import { useMemo } from 'react';
import { containsString } from '@knapsack/utils';
import { isOptionGroup } from '../../options/options.utils.js';
export const useFilteredSelectOptions = ({ options = [], searchValue, }) => {
    return useMemo(() => {
        if (!searchValue)
            return options;
        const filteredOptions = options
            .filter((option) => {
            if (isOptionGroup(option)) {
                const matchingOptions = option.options.filter((o) => containsString(o.label, searchValue));
                return matchingOptions.length > 0; // Only include groups with matching options
            }
            return containsString(option.label, searchValue);
        })
            .map((option) => {
            if (isOptionGroup(option)) {
                return {
                    label: option.label,
                    options: option.options.filter((o) => containsString(o.label, searchValue)),
                };
            }
            return option;
        });
        return filteredOptions || [];
    }, [options, searchValue]);
};
export const useSelectStateInfo = ({ disableSearch, placeholder, selectState, }) => {
    switch (selectState) {
        case 'loading':
            return {
                statePlaceholder: placeholder,
                stateIcon: {
                    size: 'small',
                    spinning: true,
                    symbol: 'loading',
                    testId: 'selectState--loading',
                },
            };
        case 'menuClosed':
            return {
                statePlaceholder: placeholder,
                stateIcon: {
                    size: 'xsmall',
                    symbol: 'caret-down',
                    testId: 'selectState--menuClosed',
                },
            };
        case 'menuOpen':
            return {
                statePlaceholder: placeholder,
                stateIcon: {
                    size: 'xsmall',
                    symbol: disableSearch ? 'caret-up' : 'search',
                    testId: 'selectState--menuOpen',
                },
            };
        case 'searching':
            return {
                statePlaceholder: 'Search...',
                stateIcon: {
                    size: 'xsmall',
                    symbol: 'search',
                    testId: 'selectState--searching',
                },
            };
        default: {
            const _exhaustiveCheck = selectState;
            return _exhaustiveCheck;
        }
    }
};
