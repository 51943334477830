import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { floatingActionStyles, } from './floating-action.css.js';
import { ButtonBase, IconButtonBase, } from '../button-base/index.js';
export const FloatingAction = forwardRef((props, ref) => {
    const { showLabel = false, ...otherProps } = props;
    if (showLabel) {
        return (_jsx(ButtonBase, { ref: ref, theme: floatingActionStyles({
                color: props.color || 'default',
                placement: props.placement,
                position: props.position,
            }), ...otherProps }));
    }
    return (_jsx(IconButtonBase, { ref: ref, theme: floatingActionStyles({
            color: props.color || 'default',
            placement: props.placement,
            position: props.position,
        }), ...otherProps }));
});
