import {
  ButtonTertiary,
  Flex,
  Icon,
  IconButtonTertiary,
  Spinner,
  TextBody,
  TextLabel,
  visuallyHidden,
} from '@knapsack/toby';
import { TextLink } from '@/components/text-link';
import { useFigmaUserInfo } from '@/domains/design-srcs/design-src-hooks';
import { handleFigmaLogin } from '@/domains/design-srcs/design-src-auth-checker';
import { appApiGql } from '@/services/app-api-client';
import { trackEvent } from '@/utils/analytics';
import { useCheckIfFigmaTokenExists } from '@/services/figma-oauth-client';
import {
  designSrcsTableCell,
  designSrcsTableHeading,
} from '@/app/(main)/site/[siteId]/[instanceId]/(inv)/design/sources/design-srcs.page.css';
import { useKsLocation } from '@/utils/ks-location.hooks';
import type { TrackEventLocation } from '@/utils/track-events';

const AccountInfo = ({
  user,
  type,
  handleRemoveAccount,
  handleConnectAccount,
  url,
}: {
  user: string;
  type: 'FIGMA';
  handleRemoveAccount: () => void;
  handleConnectAccount: () => void;
  url?: string;
}) => {
  const { type: locationType } = useKsLocation();
  return (
    <tr>
      <td className={designSrcsTableCell}>
        <Flex gap="small">
          {type === 'FIGMA' && <Icon symbol="figma" size="large" />}
          <div>
            <TextBody weight="bold" spacing="none">
              {type === 'FIGMA' && 'Figma'}
            </TextBody>
            {url && (
              <TextLink weight="regular" color="subtle" href={url}>
                {url}
              </TextLink>
            )}
          </div>
        </Flex>
      </td>
      <td className={designSrcsTableCell}>
        <div>
          {user && (
            <TextBody weight="regular" spacing="none">
              {user}
            </TextBody>
          )}
        </div>
      </td>
      <td className={designSrcsTableCell}>
        {user ? (
          <IconButtonTertiary
            color="subtle"
            icon="delete"
            label="Remove Account"
            size="small"
            onTrigger={({ event }) => {
              event.preventDefault();
              handleRemoveAccount();
              trackEvent({
                type: 'User Connected Accounts Remove Triggered',
                metadata: { service: type, locationType },
              });
            }}
          />
        ) : (
          <ButtonTertiary
            color="subtle"
            label="Connect"
            size="small"
            onTrigger={async () => {
              handleConnectAccount();
              trackEvent({
                type: 'User Connected Accounts Connect Triggered',
                metadata: { service: type, locationType },
              });
            }}
          />
        )}
      </td>
    </tr>
  );
};

export const FigmaOauthAccount = ({
  locationType,
  userId,
}: {
  locationType: TrackEventLocation;
  userId: string;
}) => {
  const {
    figmaUserInfo,
    isLoading,
    error,
    isSuccess,
    refetch: refetchFigmaUserInfo,
  } = useFigmaUserInfo(userId);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <AccountInfo
      user={figmaUserInfo?.email}
      type="FIGMA"
      handleRemoveAccount={async () => {
        if (!userId) {
          throw new Error('No userId');
        }
        if (!figmaUserInfo?.id) {
          throw new Error('No figmaUserInfo.id');
        }
        await appApiGql.deleteDesignSrcUser({
          userId,
          sourceUserId: figmaUserInfo?.id,
        });

        refetchFigmaUserInfo();
      }}
      handleConnectAccount={() => {
        handleFigmaLogin({
          locationType,
          onError: (errorMsg) => {
            throw new Error(`Error logging in to Figma ${errorMsg}`);
          },
          onSuccess: async () => {
            refetchFigmaUserInfo();
          },
        });
      }}
      url="https://www.figma.com/"
    />
  );
};

export const ConnectedAccountsMenu = ({ userId }: { userId: string }) => {
  const { type: locationType } = useKsLocation();
  const {
    data,
    isSuccess: tokenCheckSuccess,
    refetch: recheckFigmaToken,
  } = useCheckIfFigmaTokenExists();
  const hasFigmaToken = tokenCheckSuccess && data?.hasToken;
  return (
    <>
      <TextLabel color="subtle" size="medium">
        Connected Accounts
      </TextLabel>
      <table>
        <thead>
          <tr style={{ textAlign: 'left' }}>
            <th className={designSrcsTableHeading}>
              <TextLabel size="small" transform="uppercase" weight="medium">
                Account
              </TextLabel>
            </th>
            <th className={designSrcsTableHeading}>
              <TextLabel size="small" transform="uppercase" weight="medium">
                User
              </TextLabel>
            </th>
            <th className={designSrcsTableHeading}>
              <span className={visuallyHidden}>Actions</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {hasFigmaToken ? (
            <FigmaOauthAccount locationType={locationType} userId={userId} />
          ) : (
            <AccountInfo
              user=""
              type="FIGMA"
              handleRemoveAccount={() => {}}
              handleConnectAccount={() => {
                handleFigmaLogin({
                  locationType,
                  onError: (errorMsg) => {
                    throw new Error(`Error logging in to Figma ${errorMsg}`);
                  },
                  onSuccess: async () => {
                    recheckFigmaToken();
                  },
                });
              }}
              url="https://www.figma.com/"
            />
          )}
        </tbody>
      </table>
    </>
  );
};
